
.el-button + .el-button[data-v-d52eb4ca] {
  margin-left: 0px;
}
ul[data-v-d52eb4ca], li[data-v-d52eb4ca] {
  padding: 0;
  margin: 0;
  list-style: none;
}
.leftItem[data-v-d52eb4ca] {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
  line-height: 36px;
}
.leftItem[data-v-d52eb4ca]:hover {
  background-color: #F5F7FA
}
.leftItemActive[data-v-d52eb4ca] {
  color: #409eff;
  background-color: #F5F7FA
}
.simsIcon[data-v-d52eb4ca] {
  margin-left: 5px;
}
.el-tabs__content[data-v-d52eb4ca] {
  padding: 10px !important;
}
.outLine[data-v-d52eb4ca] {
  text-decoration: underline
}
.new-sino-input[data-v-d52eb4ca] {
  width: 200px;
  margin-right: 20px;
}
